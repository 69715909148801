<script>
defineOptions({
  name: 'MopCard',
});
</script>

<template>
  <div class="mop-card">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.mop-card {
  background-color: $color-surface-primary;
  padding: $space-24;
  border-radius: $border-radius-rounded-lg;
  box-shadow: $shadow-sm;
}
</style>
