import { default as indexiqiWQ2at79Meta } from "/opt/build/repo/apps/b2b-landing/pages/index.vue?macro=true";
export default [
  {
    name: indexiqiWQ2at79Meta?.name ?? "index",
    path: indexiqiWQ2at79Meta?.path ?? "/",
    meta: indexiqiWQ2at79Meta || {},
    alias: indexiqiWQ2at79Meta?.alias || [],
    redirect: indexiqiWQ2at79Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/apps/b2b-landing/pages/index.vue").then(m => m.default || m)
  }
]