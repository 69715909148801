<script setup lang="ts">
import SVGMopLogo from '@mop/shared/images/logo/logo_mop.svg?component';
import type { RouteLocationNormalizedLoaded } from 'vue-router';
const error = useError();
const route = useRouter();
const router = useRouter();

onMounted(() => {
  routeChangeMiddleware(route.currentRoute.value);
});

function routeChangeMiddleware(to: RouteLocationNormalizedLoaded) {
  if (to.fullPath !== '/') {
    router.push('/');
  }
}
</script>

<template>
  <div class="app__wrapper">
    <section class="app__content">
      <header class="app__header">
        <a href="/">
          <SVGMopLogo class="app__logo" width="260" />
        </a>
      </header>
      <MopCard v-if="error">
        {{ error?.message }}
      </MopCard>

      <NuxtPage />
    </section>
    <section class="app__side-image"></section>
  </div>
</template>

<style lang="scss" scoped>
.app__wrapper {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  width: 100%;

  @include v2-apply-upto(mobile) {
    flex-direction: column;
  }
}

.app__logo {
  width: 146px;
}

.app__header {
  padding: $space-32;
  margin-bottom: $space-64;

  @include v2-apply-upto(mobile) {
    margin-bottom: 0;
  }
}

.app__content {
  flex: 1;
  background-color: $color-surface-primary;
  padding: 0 $space-24;
}

.app__side-image {
  flex: 1;
  background: $color-surface-page no-repeat
    url('https://a.storyblok.com/f/103305/2000x1333/c58f692caf/b2b-landing-background.jpg');
  background-size: cover;
  background-position: top center;

  @include v2-apply-upto(mobile) {
    min-height: 80vw;
  }
}
</style>
